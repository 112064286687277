
.nft-list-container {
    min-height: 160px;
    max-height: 480px;
    max-width: 760px;
    color: #FFF;
  }

.nft-list {
    padding: 20px;
    overflow-x: hidden;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
    min-height: 180px;
    max-height: 320px;
    min-width: 180px;
  
    &.has-selection {
      .nft-list-item {
        opacity: 0.5;
      }
    }
  
    .nft-list-section {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      flex-wrap: wrap;
  
      .section-title {
        width: 100%;
        margin-bottom: 0.5rem;
      }
  
      + .nft-list-section {
        margin-top: 1rem;
      }
    }
  
    .nft-item-container {
      margin-bottom: 20px;
    }
  
    .nft-list-item {
      height: 120px;
      width: 120px;
      margin: 0 10px 10px 10px;
      border: 4px solid transparent;
      border-radius: 5px;
      position: relative;
      cursor: pointer;
      overflow: hidden;
  
      .nft-selected {
        display: none;
        position: absolute;
        top: 5px;
        right: 5px;
      }
  
      .nft-id {
        font-style: normal;
        position: absolute;
        bottom: 5px;
        right: 5px;
        padding: 2px 8px;
        background-color: #000;
        border-radius: 50px;
        font-weight: bold;
        font-size: 12px;
        line-height: 15px;
        color: #FFF;
      }
  
      &:hover {
        border-color: #000;
        opacity: 1;
      }
  
      &.selected {
        border-color: #000;
        opacity: 1;
        .nft-selected {
          display: block;
        }
      }
  
      img {
        height: 100%;
        width: 100%;
        border-radius: 5px;
      }
  
  
      &.complete {
        border-color: rgba(0, 128, 0, 0.5);
      }
  
      &.uncomplete {
        border-color: rgba(128, 19, 0, 0.5);
      }
    }
  
    .nft-list-empty {
      align-self: center;
      margin: 0 auto;
    }
  }
  
  .nft-list {
    &.small-list {
      padding-top: 0;
      padding-bottom: 0;
      min-height: 0;
      max-height: fit-content;
      min-width: 0;
  
      .nft-list-section {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
  
        + .nft-list-section {
          margin-top: 0rem;
        }
      }
  
      .nft-list-item {
        height: 90px;
        width: 90px;
      }
    }
  }